import React, { useState, useEffect } from "react";
import axios from "axios";

import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Button from "../../../components/custom-widgets/button";
import { first, filter } from "lodash";

let status = "Cargando...";
const initialRateData = [
  {
    LoanProgram: "30-Year Fixed Custom Construction/Remodel",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "Home Equity Line of Credit (HELOC)",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "30-Year Fixed Refinance",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "30-Year Fixed Smart Start",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "30-Year Fixed Mortgage",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "HELOAN",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  }
];

const MortgageRatesSpanish = () => {
  const [data, setData] = useState(initialRateData);
  const [isError, setIsError] = useState(false);

  const getRateByName = (rateName) => {
    return first(
      filter(data, (rate) => {
        return rate.LoanProgram === rateName;
      })
    );
  };
  const rateThirtyYearFixed = getRateByName("30-Year Fixed Mortgage");
  const rateThirtyYearFixedConstruction = getRateByName("30-Year Fixed Custom Construction/Remodel");
  const rateTenYearInterestOnlyHeloc = getRateByName("Home Equity Line of Credit (HELOC)");
  const rateThirtyYearFixedRefinance = getRateByName("30-Year Fixed Refinance");
  const rateThirtyYearFixedSmartStart = getRateByName("30-Year Fixed Smart Start");
  const rateHELOAN = getRateByName("HELOAN");

  // TODO: maybe could move this to helpers folder for reuse elsewhere
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    }).format(value);
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      active: true,
      title: "Tarifas"
    }
  ];

  const SEOData = {
    title: "Tasas de interés hipotecarias actuales",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Tasas de interés hipotecarias actuales"
      },
      {
        name: "description",
        property: "og:description",
        content: "Tasas de interés hipotecarias actuales"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/tasas-de-interes-hipotecarias-actuales"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-spanish-mortgage-rates-041923.jpg"
      }
    ]
  };

  function renderRateRow(id, title, rateRecord) {
    if (!rateRecord) {
      return status;
    }
    return (
      <tr>
        <td id={id} dangerouslySetInnerHTML={{ __html: title }} />
        <td id={id + `-interest-rate`}>
          {isError
            ? "No Disponible"
            : typeof rateRecord.InterestRate === "number"
            ? `${rateRecord.InterestRate.toFixed(3)}%`
            : rateRecord.InterestRate}
        </td>
        <td id={id + `-pi-payments`}>
          {isError
            ? "No Disponible"
            : typeof rateRecord.PIPayments === "number"
            ? formatCurrency(rateRecord.PIPayments)
            : rateRecord.PIPayments}
        </td>
        <td id={id + `-fee`}>
          {isError
            ? "No Disponible"
            : typeof rateRecord.Fee === "number"
            ? `${rateRecord.Fee.toFixed(3)}%`
            : rateRecord.Fee}
        </td>
        <td id={id + `-apr`}>
          {isError
            ? "No Disponible"
            : typeof rateRecord.APR === "number"
            ? `${rateRecord.APR.toFixed(3)}%`
            : rateRecord.APR}
        </td>
      </tr>
    );
  }

  const fetchData = () => {
    const host = window.location.hostname === "www.wafdbank.com" ? "https://wafdapi.com" : "https://test.wafdapi.com";
    axios
      .get(`${host}/mortgage-rates.json`)
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
        setIsError(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Tasas de interés hipotecarias actuales</h1>
        <div className="table-responsive mb-3">
          <table className="table table-bordered mortgage-rates" title="Mortgage Rates">
            <thead>
              <tr className="text-center d-none d-md-table-row">
                <th>
                  <strong>
                    Préstamo Hipotecario
                    <br />
                    Programa
                  </strong>
                </th>
                <th>
                  <strong>Tasa de Interés</strong>
                </th>
                <th>
                  <strong>Pagos P&amp;I</strong>
                </th>
                <th>
                  <strong>Tarifa</strong>
                </th>
                <th>
                  <strong>APR</strong>
                  <sup>&dagger;</sup>
                </th>
              </tr>
              <tr className="text-center d-table-row d-md-none">
                <th>
                  <p className="lead text-black">
                    <strong>
                      Préstamo hipotecario
                      <br />
                      Programa
                    </strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Tasa de interés</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Pagos P&amp;I</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Tarifa</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>APR</strong>
                    <sup>&dagger;</sup>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderRateRow("30-year-fixed-mortgage", "Hipoteca fija por 30 años<sup>1</sup>", rateThirtyYearFixed)}
              {renderRateRow(
                "30-year-fixed-construction",
                "30-Año de construcción / remodelación a medida fija<sup>2</sup>",
                rateThirtyYearFixedConstruction
              )}
              {renderRateRow(
                "30-year-fixed-refinance",
                "30 años de Refinanciamiento fijo anual<sup>3</sup>",
                rateThirtyYearFixedRefinance
              )}
              {renderRateRow(
                "30-year-fixed-smart-start",
                "Hipoteca Smart Start fija por 30 años<sup>4</sup>",
                rateThirtyYearFixedSmartStart
              )}
              {renderRateRow("heloan", "HELOAN<sup>5</sup>", rateHELOAN)}
            </tbody>
          </table>
        </div>
        <h2>Programas de préstamos de solo interés</h2>
        <div className="table-responsive">
          <table className="table table-bordered mortgage-rates" title="Mortgage Rates">
            <thead>
              <tr className="text-center d-none d-md-table-row">
                <th>
                  <strong>
                    Préstamo hipotecario
                    <br />
                    Programa
                  </strong>
                </th>
                <th>
                  <strong>Tasa de interés</strong>
                </th>
                <th>
                  <strong>Pagos únicamente de intereses</strong>
                </th>
                <th>
                  <strong>Tarifa</strong>
                </th>
                <th>
                  <strong>APR</strong>
                  <sup>&dagger;</sup>
                </th>
              </tr>
              <tr className="text-center d-table-row d-md-none">
                <th>
                  <p className="lead text-black">
                    <strong>
                      Préstamo hipotecario
                      <br />
                      Programa
                    </strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Tasa de interés</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Pagos P&amp;I</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Tarifa</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>APR</strong>
                    <sup>&dagger;</sup>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderRateRow(
                "heloc-interest-only-loan",
                "Línea de crédito con garantía hipotecaria (HELOC)<sup>6</sup>",
                rateTenYearInterestOnlyHeloc
              )}
            </tbody>
          </table>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="h2 mb-0">¿Comprar una casa nueva?</div>

          <div className="py-3">
            <Button
              containerClass="my-2"
              type="link"
              url="/es/banca-personal/contacte-a-un-asesor-hipotecario"
              text="Encuentra un oficial de préstamo cerca de ti"
              showIcon={false}
            />
          </div>

          <ul className="green-checkmark">
            <li>Pregúntenos sobre otros programas de préstamos disponibles.</li>
            <li>Sujeto a aprobación de crédito.</li>
            <li>La APR se basa en el monto del préstamo y la tasa de interés.</li>
            <li>
              Los pagos cotizados no incluyen impuestos ni seguro, por lo que los pagos reales pueden ser mayores.
            </li>
          </ul>
        </div>
      </section>

      <section className="container pt-0">
        <hr />

        <p id="apr-disclaimer" className="text-muted">
          <sup>&dagger;</sup> Tasa de porcentaje anual (APR) El costo de pedir dinero prestado expresado como porcentaje
          anual. Para préstamos hipotecarios, excluidas las líneas de crédito sobre el valor neto de la vivienda,
          incluye la tasa de interés más otros cargos o tarifas. Para las líneas de garantía hipotecaria, la APR es solo
          la tasa de interés.
        </p>

        <p id="30-year-fixed-mortgage-disclaimer" className="text-muted">
          <sup>1</sup> Supone la compra de una residencia unifamiliar ocupada por el propietario con un monto de
          préstamo de $320,000 basado en un precio de compra de $400,000 con un pago inicial del 20% de $80,000. Puntaje
          FICO del prestatario de 750 o más. Sujeto a calificación. La tasa de interés se aplica al programa solo de
          compra fija a 30 años. Pago mensual basado en un plazo de préstamo de 30 años amortizado en 360 meses.
          Comuníquese con el oficial de préstamos de su vecindario para obtener detalles sobre el escenario de su
          préstamo.
        </p>
        <p id="30-year-fixed-construction-disclaimer" className="text-muted">
          <sup>2</sup> Supone una construcción / remodelación personalizada de una residencia unifamiliar ocupada por el
          propietario con un monto de préstamo de $320,000 basado en un valor final y un costo para completar la
          construcción / remodelación personalizada de $400,000. Puntaje FICO del prestatario de 750 o más. Sujeto a
          calificación. La tasa de interés se aplica solo al programa fijo de construcción / remodelación personalizada
          de 30 años. Pago mensual basado en un plazo de préstamo de 30 años amortizado en 360 meses. Comuníquese con el
          oficial de préstamos de su vecindario para obtener detalles sobre el escenario de su préstamo. Este producto
          no está disponible en California.
        </p>
        <p id="30-year-fixed-refinance-disclaimer" className="text-muted">
          <sup>3</sup> Las tarifas reales pueden variar. Supone un refinanciamiento sin retiro de efectivo de una
          residencia unifamiliar ocupada por el propietario con un monto de préstamo de $320,000 basado en un valor de
          tasación de la propiedad de $400,000 con un 20% de capital. Puntaje FICO del prestatario de 750 o más. Sujeto
          a calificación. La tasa de interés se aplica a un programa de refinanciamiento de tasa fija de 30 años. Las
          opciones de refinanciamiento con retiro de efectivo pueden estar disponibles con diferentes tasas y términos.
          Comuníquese con el oficial de préstamos de su vecindario para obtener detalles sobre el escenario de su
          préstamo.
        </p>
        <p id="30-year-fixed-smart-start-disclaimer" className="text-muted">
          <sup>4</sup> Los prestatarios calificados deben cumplir con los requisitos de elegibilidad que incluyen, entre
          otros, ser propietarios-ocupantes, cumplir con ciertas limitaciones de ingresos que califican y comprar una
          casa dentro de un área geográfica determinada. El valor máximo del préstamo es del 97% y el monto máximo del
          préstamo es de $766,550. Se requiere un pago inicial mínimo del 3%. Prestatario(s) Puntuación FICO de 640 o
          superior. Sujeto a calificación y aprobación de crédito. La tasa de interés se aplica a un programa de solo
          compra fija a 30 años. Pagos mensuales basados ​​en un plazo de préstamo de 30 años amortizado en 360 meses.
        </p>
        <p id="heloan-disclaimer" className="text-muted">
          <sup>5</sup> Sujeto a aprobación de crédito. HELOAN asume un monto de $100,000 y que (1) el prestatario tiene
          una primera hipoteca existente de WaFd Bank, (2) que la propiedad en cuestión está ocupada por el propietario,
          (3) los prestatarios tienen FICO de 750 o más, (4) el préstamo combinado para valorar es máximo del 60%. Pago
          mensual basado en un plazo de préstamo de 20 años amortizado en 240 meses. Comuníquese con su oficial de
          préstamos local para obtener detalles sobre la situación de su préstamo.
        </p>
        <p id="heloc-interest-only-loan-disclaimer" className="text-muted">
          <sup>6</sup> Sujeta/o a aprobacion de credito. Valor Acumulado de la Vivienda Tasas de interes y gastos
          asumiendo que la linea de credito is de $100,000 y que el (1) solicitante tenga un prestamo epotecario con
          WaFd Bank. (2) La vivienda sea ocupada for el solicitante. (3) El/La solicitante tenga una puntuacion FICO de
          750 o mas. (4) La combinacion de el prestamo al valor de la vivienda sea mas que 60% y (5) Inscribirse en
          pagos automatizados de EZ-Pay de una cuenta de cheques de WaFd Bank.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default MortgageRatesSpanish;
